var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-container',{attrs:{"fluid":""}},[_c('v-row',[_c('v-col',{attrs:{"cols":"12"}},[_c('v-card',{attrs:{"elevation":"","max-width":""}},[_c('v-card-title',{staticClass:"justify-center"},[_vm._v(" MODIFICAR BLOG ")]),_c('v-divider'),_c('v-card-text',[_c('v-row',[_c('v-col',{attrs:{"cols":"12"}},[_c('p',[_c('router-link',{staticStyle:{"text-decoration":"none"},attrs:{"exact":"","to":{ name: 'inicioPanel' }}},[_vm._v("Inicio")]),_vm._v(" / "),_c('router-link',{staticStyle:{"text-decoration":"none"},attrs:{"exact":"","to":{ name: 'blogsPanel' }}},[_vm._v("Blogs")]),_vm._v(" / "),_c('router-link',{staticStyle:{"text-decoration":"none"},attrs:{"exact":"","to":{
                    name: 'blogsEditarPanel',
                    params: { idblog: _vm.$route.params.idblog },
                  }}},[_vm._v("Editar")])],1)]),_c('v-col',{attrs:{"cols":"12"}},[_c('v-divider')],1)],1),_c('v-row',[_c('v-col',{directives:[{name:"show",rawName:"v-show",value:(!_vm.mostrarContenido),expression:"!mostrarContenido"}],attrs:{"cols":"12 text-center"}},[_c('v-progress-circular',{attrs:{"indeterminate":"","color":"primary","size":100,"width":7}})],1)],1),_c('v-form',{directives:[{name:"show",rawName:"v-show",value:(_vm.mostrarContenido),expression:"mostrarContenido"}],attrs:{"disabled":_vm.processForm},on:{"submit":function($event){$event.preventDefault();return _vm.modificar()}}},[_c('v-row',[_c('v-col',{attrs:{"cols":"12"}},[_c('v-alert',{ref:"mensajeError",attrs:{"type":"error","dismissible":"","id":"mensajeError"},model:{value:(_vm.alertError),callback:function ($$v) {_vm.alertError=$$v},expression:"alertError"}},_vm._l((_vm.listErrors),function(errors,index){return _c('ul',{key:index},[_c('li',{domProps:{"textContent":_vm._s(errors[0])}})])}),0)],1),_c('v-col',{attrs:{"cols":"12"}},[_c('v-text-field',{attrs:{"dense":"","label":"Titulo blog (*)"},model:{value:(_vm.blog.titulo),callback:function ($$v) {_vm.$set(_vm.blog, "titulo", $$v)},expression:"blog.titulo"}})],1),_c('v-col',{attrs:{"cols":"12"}},[_c('v-textarea',{attrs:{"dense":"","rows":"3","clearable":"","clear-icon":"mdi-close-circle","label":"Descripción"},model:{value:(_vm.blog.descripcion),callback:function ($$v) {_vm.$set(_vm.blog, "descripcion", $$v)},expression:"blog.descripcion"}})],1),_c('v-col',{attrs:{"cols":"12"}},[_c('p',{staticClass:"my-0"},[_vm._v("Contenido")]),_c('ckeditor',{attrs:{"editor-url":_vm.apiUrl('js/ckeditor/ckeditor.js'),"config":{
                    filebrowserBrowseUrl: '/plugin_externos/ckfinder/ckfinder.html',
                    allowedContent: true,
                  }},model:{value:(_vm.blog.contenido),callback:function ($$v) {_vm.$set(_vm.blog, "contenido", $$v)},expression:"blog.contenido"}})],1),_c('v-col',{attrs:{"xl":"6","lg":"6","md":"6","sm":"12","cols":"12"}},[_c('p',[_vm._v("Banner:")]),_c('input',{ref:"iptBanner",staticStyle:{"display":"none"},attrs:{"type":"file","accept":"image/*"},on:{"change":function($event){$event.preventDefault();return _vm.cargarBanner($event)}}}),_c('div',{staticClass:"custom-my-dropzone"},[_c('v-row',[_c('v-col',{directives:[{name:"show",rawName:"v-show",value:(_vm.urlBanner === ''),expression:"urlBanner === ''"}],staticClass:"text-center",attrs:{"cols":"12"}},[_c('v-btn',{attrs:{"loading":_vm.loaderBanner,"color":"primary"},on:{"click":function($event){_vm.$refs.iptBanner.value = '';
                          _vm.$refs.iptBanner.click();}}},[_c('v-icon',{attrs:{"left":""}},[_vm._v("mdi-cloud-upload-outline")]),_vm._v(" CARGAR BANNER ")],1)],1),_c('v-col',{directives:[{name:"show",rawName:"v-show",value:(_vm.urlBanner !== ''),expression:"urlBanner !== ''"}],staticClass:"text-center",attrs:{"cols":"12"}},[_c('v-btn',{attrs:{"icon":"","color":"red","small":""},on:{"click":function($event){$event.preventDefault();return _vm.eliminarBanner($event)}}},[_c('v-icon',[_vm._v("mdi-trash-can")])],1),_c('v-img',{attrs:{"src":_vm.urlBanner,"height":"200","contain":""}})],1)],1)],1)]),_c('v-col',{attrs:{"xl":"6","lg":"6","md":"6","sm":"12","cols":"12"}},[_c('p',[_vm._v("Imagen Compartir:")]),_c('input',{ref:"iptImagenCompartir",staticStyle:{"display":"none"},attrs:{"type":"file","accept":"image/*"},on:{"change":function($event){$event.preventDefault();return _vm.cargarImagenCompartir($event)}}}),_c('div',{staticClass:"custom-my-dropzone"},[_c('v-row',[_c('v-col',{directives:[{name:"show",rawName:"v-show",value:(_vm.urlImagenCompartir === ''),expression:"urlImagenCompartir === ''"}],staticClass:"text-center",attrs:{"cols":"12"}},[_c('v-btn',{attrs:{"loading":_vm.loaderImagenCompartir,"color":"primary"},on:{"click":function($event){_vm.$refs.iptImagenCompartir.value = '';
                          _vm.$refs.iptImagenCompartir.click();}}},[_c('v-icon',{attrs:{"left":""}},[_vm._v("mdi-cloud-upload-outline")]),_vm._v(" CARGAR IMAGEN COMPARTIR ")],1)],1),_c('v-col',{directives:[{name:"show",rawName:"v-show",value:(_vm.urlImagenCompartir !== ''),expression:"urlImagenCompartir !== ''"}],staticClass:"text-center",attrs:{"cols":"12"}},[_c('v-btn',{attrs:{"icon":"","color":"red","small":""},on:{"click":function($event){$event.preventDefault();return _vm.eliminarImagenCompartir($event)}}},[_c('v-icon',[_vm._v("mdi-trash-can")])],1),_c('v-img',{attrs:{"src":_vm.urlImagenCompartir,"height":"200","contain":""}})],1)],1)],1)]),_c('v-col',{attrs:{"cols":"12"}},[_c('p',{staticClass:"red--text"}),_c('v-select',{attrs:{"dense":"","item-text":"texto","item-value":"valor","items":_vm.cmbEstado,"label":"Estado(*)","outlined":""},model:{value:(_vm.blog.estado),callback:function ($$v) {_vm.$set(_vm.blog, "estado", $$v)},expression:"blog.estado"}})],1),_c('v-col',{staticClass:"text-center",attrs:{"cols":"12"}},[_c('v-btn',{staticClass:"mr-2",attrs:{"type":"submit","loading":_vm.processForm,"color":"success","large":""}},[_c('v-icon',{attrs:{"left":"","large":""}},[_vm._v("mdi-content-save-edit")]),_vm._v(" MODIFICAR ")],1),_c('v-btn',{attrs:{"to":{ name: 'blogsPanel' },"type":"button","loading":_vm.processForm,"color":"error","large":""}},[_c('v-icon',{attrs:{"left":"","large":""}},[_vm._v("mdi-close")]),_vm._v(" CANCELAR ")],1)],1)],1)],1)],1)],1)],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }