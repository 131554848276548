<template>
  <v-container fluid>
    <v-row>
      <v-col cols="12">
        <v-card class="" elevation="" max-width="">
          <v-card-title class="justify-center"> MODIFICAR BLOG </v-card-title>
          <v-divider></v-divider>
          <v-card-text>
            <v-row>
              <v-col cols="12">
                <p>
                  <router-link
                    exact=""
                    style="text-decoration: none"
                    :to="{ name: 'inicioPanel' }"
                    >Inicio</router-link
                  >
                  /
                  <router-link
                    exact=""
                    style="text-decoration: none"
                    :to="{ name: 'blogsPanel' }"
                    >Blogs</router-link
                  >
                  /
                  <router-link
                    exact=""
                    style="text-decoration: none"
                    :to="{
                      name: 'blogsEditarPanel',
                      params: { idblog: $route.params.idblog },
                    }"
                    >Editar</router-link
                  >
                </p>
              </v-col>
              <v-col cols="12">
                <v-divider></v-divider>
              </v-col>
            </v-row>

            <v-row>
              <v-col cols="12 text-center" v-show="!mostrarContenido">
                <v-progress-circular
                  indeterminate
                  color="primary"
                  :size="100"
                  :width="7"
                ></v-progress-circular>
              </v-col>
            </v-row>

            <v-form
              @submit.prevent="modificar()"
              v-show="mostrarContenido"
              :disabled="processForm"
            >
              <v-row>
                <v-col cols="12">
                  <v-alert
                    type="error"
                    v-model="alertError"
                    dismissible
                    ref="mensajeError"
                    id="mensajeError"
                  >
                    <ul v-for="(errors, index) in listErrors" :key="index">
                      <li v-text="errors[0]"></li>
                    </ul>
                  </v-alert>
                </v-col>

                <v-col cols="12">
                  <v-text-field
                    dense
                    label="Titulo blog (*)"
                    v-model="blog.titulo"
                  ></v-text-field>
                </v-col>

                <v-col cols="12">
                  <v-textarea
                    dense
                    rows="3"
                    clearable
                    clear-icon="mdi-close-circle"
                    label="Descripción"
                    v-model="blog.descripcion"
                  ></v-textarea>
                </v-col>

                <v-col cols="12">
                  <p class="my-0">Contenido</p>
                  <ckeditor
                    :editor-url="apiUrl('js/ckeditor/ckeditor.js')"
                    v-model="blog.contenido"
                    :config="{
                      filebrowserBrowseUrl: '/plugin_externos/ckfinder/ckfinder.html',
                      allowedContent: true,
                    }"
                  ></ckeditor>
                </v-col>

                <v-col xl="6" lg="6" md="6" sm="12" cols="12">
                  <p>Banner:</p>
                  <input
                    style="display: none"
                    type="file"
                    ref="iptBanner"
                    @change.prevent="cargarBanner"
                    accept="image/*"
                  />
                  <div class="custom-my-dropzone">
                    <v-row>
                      <v-col cols="12" class="text-center" v-show="urlBanner === ''">
                        <v-btn
                          :loading="loaderBanner"
                          color="primary"
                          @click="
                            $refs.iptBanner.value = '';
                            $refs.iptBanner.click();
                          "
                        >
                          <v-icon left>mdi-cloud-upload-outline</v-icon> CARGAR BANNER
                        </v-btn>
                      </v-col>
                      <v-col cols="12" class="text-center" v-show="urlBanner !== ''">
                        <v-btn icon color="red" small @click.prevent="eliminarBanner">
                          <v-icon>mdi-trash-can</v-icon>
                        </v-btn>
                        <v-img :src="urlBanner" height="200" contain> </v-img>
                      </v-col>
                    </v-row>
                  </div>
                </v-col>

                <v-col xl="6" lg="6" md="6" sm="12" cols="12">
                  <p>Imagen Compartir:</p>
                  <input
                    style="display: none"
                    type="file"
                    ref="iptImagenCompartir"
                    @change.prevent="cargarImagenCompartir"
                    accept="image/*"
                  />
                  <div class="custom-my-dropzone">
                    <v-row>
                      <v-col
                        cols="12"
                        class="text-center"
                        v-show="urlImagenCompartir === ''"
                      >
                        <v-btn
                          :loading="loaderImagenCompartir"
                          color="primary"
                          @click="
                            $refs.iptImagenCompartir.value = '';
                            $refs.iptImagenCompartir.click();
                          "
                        >
                          <v-icon left>mdi-cloud-upload-outline</v-icon> CARGAR IMAGEN
                          COMPARTIR
                        </v-btn>
                      </v-col>
                      <v-col
                        cols="12"
                        class="text-center"
                        v-show="urlImagenCompartir !== ''"
                      >
                        <v-btn
                          icon
                          color="red"
                          small
                          @click.prevent="eliminarImagenCompartir"
                        >
                          <v-icon>mdi-trash-can</v-icon>
                        </v-btn>
                        <v-img :src="urlImagenCompartir" height="200" contain> </v-img>
                      </v-col>
                    </v-row>
                  </div>
                </v-col>

                <v-col cols="12">
                  <p class="red--text"></p>
                  <v-select
                    dense
                    item-text="texto"
                    item-value="valor"
                    :items="cmbEstado"
                    label="Estado(*)"
                    outlined
                    v-model="blog.estado"
                  ></v-select>
                </v-col>

                <v-col cols="12" class="text-center">
                  <v-btn
                    class="mr-2"
                    type="submit"
                    :loading="processForm"
                    color="success"
                    large=""
                  >
                    <v-icon left="" large="">mdi-content-save-edit</v-icon> MODIFICAR
                  </v-btn>
                  <v-btn
                    :to="{ name: 'blogsPanel' }"
                    type="button"
                    :loading="processForm"
                    color="error"
                    large=""
                  >
                    <v-icon left="" large="">mdi-close</v-icon> CANCELAR
                  </v-btn>
                </v-col>
              </v-row>
            </v-form>
          </v-card-text>
        </v-card>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
export default {
  data() {
    return {
      blog: {
        _method: "PUT",
        idblog: "",
        titulo: "",
        contenido: "",
        google: "",
        descripcion: "",
        banner: "",
        imagen_compartir: "",
        estado: 1,
      },
      cmbEstado: [
        {
          texto: "Habilitado",
          valor: 1,
        },
        {
          texto: "Inhabilitado",
          valor: 0,
        },
      ],
      mostrarContenido: false,
      processForm: false,
      listErrors: [],
      alertError: false,
      loaderBanner: false,
      urlBanner: "",
      loaderImagenCompartir: false,
      urlImagenCompartir: "",
    };
  },

  methods: {
    cargarBanner(event) {
      this.loaderBanner = true;

      if (this.urlBanner !== "") {
        this.$toasted.error("Ya hay un archivo cargado");
        this.loaderBanner = false;
        return;
      }

      if (event.target.files.length > 0) {
        let banner = event.target.files[0];
        this.urlBanner = URL.createObjectURL(banner);
        this.blog.banner = banner;
      }

      this.loaderBanner = false;
    },

    eliminarBanner() {
      this.urlBanner = "";
      this.blog.banner = "";
      this.axios
        .post("api/panel/blogs/eliminarArchivo", {
          idblog: this.blog.idblog,
          archivo: "banner",
        })
        .then((response) => {
          if (response.status === 200) {
            this.$toasted.success(response.data.data);
          } else {
            this.$toasted.error("Error al eliminar el archivo");
          }
        })
        .catch((error) => {
          console.log(error);
        });
    },

    cargarImagenCompartir(event) {
      this.loaderImagenCompartir = true;

      if (this.urlImagenCompartir !== "") {
        this.$toasted.error("Ya hay un archivo cargado");
        this.loaderImagenCompartir = false;
        return;
      }

      if (event.target.files.length > 0) {
        let imagenCompartir = event.target.files[0];
        this.urlImagenCompartir = URL.createObjectURL(imagenCompartir);
        this.blog.imagen_compartir = imagenCompartir;
      }

      this.loaderImagenCompartir = false;
    },

    eliminarImagenCompartir() {
      this.urlImagenCompartir = "";
      this.blog.imagen_compartir = "";
      this.axios
        .post("api/panel/blogs/eliminarArchivo", {
          idblog: this.blog.idblog,
          archivo: "imagen_compartir",
        })
        .then((response) => {
          if (response.status === 200) {
            this.$toasted.success(response.data.data);
          } else {
            this.$toasted.error("Error al eliminar el archivo");
          }
        })
        .catch((error) => {
          console.log(error);
        });
    },

    getDatos() {
      this.mostrarContenido = false;
      this.axios({
        method: "GET",
        url: "api/panel/blogs/" + this.$route.params.idblog + "/edit",
      })
        .then((response) => {
          let status = response.status;
          if (status === 200) {
            let blog = response.data.data;

            this.blog.idblog = blog.idblog;
            this.blog.titulo = blog.titulo;
            this.blog.contenido = blog.contenido;
            this.blog.google = blog.google;
            this.blog.descripcion = blog.descripcion;

            if (blog.banner != null) {
              this.urlBanner = this.assetAWS("blogs/" + blog.banner);
            }
            if (blog.imagen_compartir != null) {
              this.urlImagenCompartir = this.assetAWS("blogs2/" + blog.imagen_compartir);
            }
            this.blog.estado = blog.estado;
          }

          this.mostrarContenido = true;
        })
        .catch((error) => {
          this.$toasted.error("Ocurrio un error al buscar este registro", {
            icon: "mdi-close",
          });

          this.$router.push({
            name: "blogsPanel",
          });
        })
        .finally(() => {});
    },

    modificar() {
      this.processForm = true;
      this.alertError = false;

      this.axios({
        method: "POST",
        url: "api/panel/blogs/" + this.blog.idblog,
        data: this.serialize(this.blog),
      })
        .then((response) => {
          let status = response.status;
          if (status === 200) {
            this.$toasted.success(response.data.data, {
              icon: "mdi-check-bold",
            });
            this.$router.push({
              name: "blogsPanel",
            });
          }
        })
        .catch((error) => {
          let status = error.response.status;
          if (status === 422) {
            let arrErrors = error.response.data.errors;
            this.listErrors = Object.values(arrErrors);
            this.alertError = true;
            this.$vuetify.goTo(this.$refs.mensajeError);
          } else {
            this.$toasted.error("Ocurrio un error al modificar este registro", {
              icon: "mdi-close",
            });
          }
        })
        .finally(() => {
          this.processForm = false;
        });
    },
  },
  created() {
    if (!this.validarPermiso("blogs.editar")) {
      this.$toasted.error("Usted no tiene permisos para esta sección", {
        icon: "mdi-close",
      });

      this.$router.push({
        name: "inicioPanel",
      });
    }
    window.scrollTo(0, 0);

    this.getDatos();
  },
};
</script>
